import { TypographyStyle, TypographyStyleOptions } from "@mui/material/styles/createTypography";

interface CustomTypography {
  [index: string]: TypographyStyleOptions | TypographyStyle | string | number;
}

declare module "@mui/material/styles/createTypography" {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-empty-interface
  interface TypographyOptions extends CustomTypography {}
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    intro: React.CSSProperties;
    introInline: React.CSSProperties;
    default: React.CSSProperties;
    main: React.CSSProperties;
    mainMedium: React.CSSProperties;
    mainSemiBold: React.CSSProperties;
    mainBold?: React.CSSProperties;
    buttonText: React.CSSProperties;
  }

  // eslint-disable-next-line no-unused-vars
  interface TypographyVariantsOptions {
    intro?: React.CSSProperties;
    introInline?: React.CSSProperties;
    default?: React.CSSProperties;
    main?: React.CSSProperties;
    mainMedium?: React.CSSProperties;
    mainSemiBold?: React.CSSProperties;
    mainBold?: React.CSSProperties;
    mainExtraBold?: React.CSSProperties;
    mainBlack?: React.CSSProperties;
    buttonText?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    intro: true;
    introInline: true;
    default: true;
    main: true;
    mainMedium: true;
    mainSemiBold: true;
    mainBold: true;
    mainExtraBold: true;
    mainBlack: true;
    buttonText: true;
  }
}

export const customVariant = {
  h1: {
    fontFamily: "PoppinsSemiBold",
    fontSize: "100px",
  },
  h2: {
    fontFamily: "PoppinsSemiBold",
    fontSize: "36px",
  },
  h3: {},
  h4: {},
  h5: {},
  h6: {},
  subtitle1: {
    fontFamily: "PoppinsMedium",
    fontSize: "30px",
  },
  subtitle2: {
    fontFamily: "PoppinsSemiBold",
    fontSize: "26px",
  },
  body1: {
    fontFamily: "Inter",
    fontSize: "16px",
  },
  body2: {
    fontFamily: "PoppinsSemiBold",
    fontSize: "16px",
  },
  caption: {
    fontFamily: "Poppins",
    fontSize: "16px",
  },
  button: {
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  main: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
  },
  mainSemiBold: {
    fontFamily: "Inter",
    fontWeight: 600,
  },
  mainBold: {
    fontFamily: "Inter",
    fontWeight: 700,
  },
  mainExtraBold: {
    fontFamily: "Inter",
    fontWeight: 800,
  },
  mainBlack: {
    fontFamily: "Inter",
    fontWeight: 900,
  },
};
