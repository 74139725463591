import { Box } from "@mui/material";
import { FC } from "react";
import { TimetableModel } from "../../../../../types/timetable-type";
import TimetableHeader from "./timetable-header/timetable-header.component";
import TimetableLabel from "./timetable-label-box/timetable-label.components";
import TimetableListBox from "./timetable-list-box/timetable-list-box.component";

type Props = {
  timetableData: TimetableModel;
};

const TimetableBox: FC<Props> = ({ timetableData }) => {
  return (
    <Box className="timetable-box desktop">
      <TimetableHeader tableName={timetableData.tableName} />
      <TimetableLabel />
      <TimetableListBox speakersList={timetableData.speakers} />
    </Box>
  );
};

export default TimetableBox;
