import { Box, styled } from "@mui/material";

const StyledWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  marginTop: "180px",
  backgroundColor: theme.palette.white.main,
  paddingBottom: "30px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "80px",
  },
  ".faq-content": {
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  ".faq-topic-box": {
    display: "flex",
    flexDirection: "column",
  },
  ".faq-topic-title": {
    fontSize: "32px",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  ".faq-topic-subtitle": {
    fontSize: "24px",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  ".faq-topic-description": {
    textAlign: "start",
  },
}));

export default StyledWrapper;
