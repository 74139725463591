import { Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "#000000e6",
  width: "100%",
  height: "230px",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {
    height: "200px",
  },
  [theme.breakpoints.down("lg")]: {
    height: "160px",
  },
  [theme.breakpoints.down("md")]: {
    height: "120x",
  },
  [theme.breakpoints.down("sm")]: {
    height: "80px",
  },
  ".information-container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      justifyContent: "space-around",
    },
  },
  ".information-box": {
    display: "flex",
    gap: "15px",
    [theme.breakpoints.down("xl")]: {
      gap: "12px",
    },
    [theme.breakpoints.down("lg")]: {
      gap: "10px",
    },
    [theme.breakpoints.down("md")]: {
      gap: "5px",
    },
    [theme.breakpoints.down("sm")]: {
      gap: "3px",
    },
  },
  ".information-animated-box": {
    fontFamily: "Inter",
    fontWeight: 900,
    fontSize: "60px",
    lineHeight: "72px",
    textAlign: "center",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xl")]: {
      fontSize: "50px",
      lineHeight: "62px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "36px",
      lineHeight: "42px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "26px",
      lineHeight: "32px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  ".information-label": {
    fontFamily: "Inter",
    fontWeight: 900,
    fontSize: "60px",
    lineHeight: "72px",
    color: theme.palette.white.main,
    [theme.breakpoints.down("xl")]: {
      fontSize: "50px",
      lineHeight: "62px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "36px",
      lineHeight: "42px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "26px",
      lineHeight: "32px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
}));

export default StyledWrapper;
