import { Box } from "@mui/material";
import { FC } from "react";

const TimetableLabel: FC = () => {
  return (
    <Box className="timetable-label-box">
      {/* <Typography className="text" component={"span"} variant="main">
        {speakerLabel}
      </Typography>
      <Typography className="text" component={"span"} variant="main">
        {timeLabel}
      </Typography> */}
    </Box>
  );
};

export default TimetableLabel;
