import { Grid } from "@mui/material";
import { styled } from "@mui/system";
import backgroundImage from "../../../assets/images/speaker-popover-background.png";

const StyledWrapper = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  width: "331px",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    width: "303px",
    gap: "7px",
  },
  ".person-image": {
    borderRadius: "50%",
    width: "300px",
    height: "300px",
    overflow: "hidden",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      width: "250px",
      height: "250px",
    },
  },
  ".person-image.active-popover": {
    zIndex: 10,
    [theme.breakpoints.down("sm")]: {
      zIndex: 0,
    },
  },
  ".person-overlay-box": {
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      mixBlendMode: "multiply",
    },
  },
  ".person-overlay-box.active-popover": {
    backgroundColor: theme.palette.primary.main,
    mixBlendMode: "multiply",
  },
  ".person-content-holder": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "5px",
  },
  ".person-name-holder": {
    display: "flex",
    gap: "10px",
  },
  ".person-firstname": {
    textWrap: "nowrap",
    fontSize: "30px",
    lineHeight: "35px",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "29px",
    },
  },
  ".person-firstname.organizer": {
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.white.main,
    },
  },
  ".person-lastname": {
    textWrap: "nowrap",
    fontSize: "28px",
    lineHeight: "35px",
    color: theme.palette.common.black,
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "29px",
    },
  },
  ".person-lastname.organizer": {
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.white.main,
    },
  },
  ".person-role": {
    fontSize: "22px",
    lineHeight: "26px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "19px",
    },
  },
  ".person-role.organizer": {
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.white.main,
    },
  },
  ".popover": {
    position: "absolute",
    top: "145px",
    left: "-114px",
    right: 0,
    bottom: 0,
    zIndex: 0,
    transformTrasnlate: "3d(0,0,0)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundImage: `url(${backgroundImage})`,
    width: "560px",
    height: "560px",
    transition: "visibility 0s, opacity 0.3s linear",
    overflow: "hidden",
    borderRadius: "19px",
    opacity: 0,
    visibility: "hidden",
    [theme.breakpoints.down("lg")]: {
      width: "450px",
      height: "500px",
      top: "210px",
      left: "-65px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "400px",
      height: "360px",
      top: "0",
      left: "-50px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "360px",
      top: "0",
      left: "-0px",
    },
  },
  ".popover-person-name-holder": {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
  },
  ".popover.speakers": {
    backgroundImage: `url(${backgroundImage})`,
  },
  ".popover.active": {
    zIndex: "2",
    opacity: 1,
    visibility: "visible",
  },
  ".popover-overlay": {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    mixBlendMode: "multiply",
    position: "absolute",
    zIndex: 3,
  },
  ".popover-overlay.organizer": {
    backgroundColor: theme.palette.white.main,
  },
  ".popover-text-box": {
    position: "absolute",
    zIndex: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "0 30px",
    height: "100%",
    top: "170px",
    [theme.breakpoints.down("lg")]: {
      top: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px 24px",
      justifyContent: "center",
      bottom: "unset",
      top: "0",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 16px",
    },
  },
  ".popover-name": {
    fontSize: "28px",
    lineHeight: "35px",
    textAlign: "center",
    color: theme.palette.white.main,
    marginBottom: "5px",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ".popover-role": {
    fontSize: "24px",
    lineHeight: "24px",
    textAlign: "center",
    color: theme.palette.white.main,
    marginBottom: "20px",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ".popover-description": {
    fontSize: "18px",
    lineHeight: "24px",
    textAlign: "center",
    color: theme.palette.white.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "24px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  ".popover-description.organizer": {
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.common.black,
    },
  },
}));

export default StyledWrapper;
