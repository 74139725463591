import { Container } from "@mui/material";
import { styled } from "@mui/system";

export const StyledWrapper = styled(Container)(({ theme }) => ({
  maxWidth: theme.breakpoints.values.xl,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  ".logo-holder": {
    width: "230px",
    display: "flex",
    alignItems: "center",
    "& img": {
      width: "100%",
      cursor: "pointer",
    },
  },
  ".menu-item-link": {
    textDecoration: "none",
  },
  ".menu-item-label": {
    textDecoration: "none",
    color: theme.palette.white.main,
    fontSize: 24,
    fontWeight: 600,
    cursor: "pointer",
    ":hover": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      fontWeight: 500,
    },
  },
}));
