import { Grid } from "@mui/material";
import { styled } from "@mui/system";

const StyledWrapper = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  columnGap: "160px",
  rowGap: "66px",
  margin: "auto",
  [theme.breakpoints.down("sm")]: {
    rowGap: "0",
  },
}));

export default StyledWrapper;
