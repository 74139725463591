import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import locales from "../locales";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      hu: {
        translation: locales.translationHu,
      },
      en: {
        translation: locales.translationEn,
      },

      ro: {
        translation: locales.translationRo,
      },
    },
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

i18n.languages = ["en", "ro", "hu"];

export default i18n;
