import React from "react";
import Title from "../../../../common/title/title.components";
import Description from "../../../../common/description/description.components";
import { Box } from "@mui/material";
import StyledWrapper from "./about-ted-last-section.styles";
import { useTranslation } from "react-i18next";
import bottomImage from "../../../../assets/images/about-ted/bottom-image.jpg";
import topImage from "../../../../assets/images/about-ted/top-image.jpg";

type Title = {
  label: string;
  color: string;
};

const AboutTedLastSection = () => {
  const { t } = useTranslation();
  const titles = t("aboutTed.title", { returnObjects: true }) as Title[];
  return (
    <StyledWrapper>
      <Box className="about-title-box">
        {titles.map((title, index) => (
          <Title
            className="about-ted-title"
            text={title.label}
            color={title.color}
            key={"ted" + index}
          />
        ))}
      </Box>
      <Box className="about-ted-description-holder">
        <Description className="about-description" text={t("aboutTed.paragraphs")} />
      </Box>
      <Box className="image-box-holder">
        <Box className="bottom-image">
          <img src={bottomImage} alt="" />
        </Box>
        <Box className="top-image">
          <img src={topImage} alt="" />
        </Box>
      </Box>
    </StyledWrapper>
  );
};

export default AboutTedLastSection;
