import speaker1 from "../assets/images/speakers/speaker1.png";
import speaker2 from "../assets/images/speakers/speaker2.png";
import speaker3 from "../assets/images/speakers/speaker3.png";
import speaker4 from "../assets/images/speakers/speaker4.png";
import speaker5 from "../assets/images/speakers/speaker5.png";
import speaker6 from "../assets/images/speakers/speaker6.png";
import speaker7 from "../assets/images/speakers/speaker7.png";
import speaker9 from "../assets/images/speakers/speaker9.png";
import speaker10 from "../assets/images/speakers/speaker10.png";
import speaker11 from "../assets/images/speakers/speaker11.png";
import speaker12 from "../assets/images/speakers/speaker12.png";
import speaker13 from "../assets/images/speakers/speaker13.png";

export const SpeakersImages = [
  speaker1,
  speaker2,
  speaker3,
  speaker4,
  speaker5,
  speaker6,
  speaker7,
  speaker9,
  speaker10,
  speaker11,
  speaker12,
  speaker13,
];
