import main1 from "../assets/images/partners/main-partners/harmopan.png";
import main2 from "../assets/images/partners/main-partners/eco-tiny.png";
import main3 from "../assets/images/partners/special-partners/garden.png";
import main4 from "../assets/images/partners/special-partners/ribbon.png";

import spec1 from "../assets/images/partners/special-partners/secoplan.png";
import spec2 from "../assets/images/partners/special-partners/octopus.png";
import spec3 from "../assets/images/partners/special-partners/colorpoint.png";

import partner1 from "../assets/images/partners/partners/dreambox.png";
import partner2 from "../assets/images/partners/partners/zakarias.png";
import partner3 from "../assets/images/partners/partners/birophoto.png";
import partner4 from "../assets/images/partners/partners/petofi_kavehaz.png";
import partner5 from "../assets/images/partners/partners/sazy.png";
import partner6 from "../assets/images/partners/partners/elitoptika.png";
import partner7 from "../assets/images/partners/partners/bogancs.png";
import partner8 from "../assets/images/partners/partners/rubiq.png";
import partner9 from "../assets/images/partners/partners/muzal.png";
import partner10 from "../assets/images/partners/partners/szikszai.png";
import partner11 from "../assets/images/partners/partners/henter.png";
import partner12 from "../assets/images/partners/partners/amigo.png";

import sPartner1 from "../assets/images/partners/separeted-partners/varoshaza.png";
import sPartner2 from "../assets/images/partners/separeted-partners/fejlesztesi-ugynokseg.png";

export const mainPartners = [
  {
    image: main1,
    link: "https://harmopan.ro",
  },
  {
    image: main2,
    link: "https://www.ecotiny.house",
  },
  {
    image: main3,
    link: "https://gardenproiect.ro",
  },
  {
    image: main4,
    link: "https://ribon.ro/",
  },
];

export const specialPartners = [
  {
    image: spec1,
    link: "https://www.secoplan.ro",
  },
  {
    image: spec2,
    link: "https://theoctopus.ro/",
  },
  {
    image: spec3,
    link: "https://colorpointadvertising.ro",
  },
];

export const partners = [
  {
    image: partner1,
    link: "https://dreamboxfoto.ro",
  },
  {
    image: partner2,
    link: "https://www.booking.com/hotel/ro/zakarias-apartments-miercurea-ciuc.hu.html",
  },
  {
    image: partner3,
    link: "https://www.facebook.com/birophotography/",
  },
  {
    image: partner4,
    link: "https://www.facebook.com/profile.php?id=100063718923940",
  },
  {
    image: partner5,
    link: "https://sazy.ro/",
  },
  {
    image: partner6,
    link: "https://elitoptica.ro/",
  },
  {
    image: partner7,
    link: "https://www.facebook.com/bogancs.net",
  },
  {
    image: partner8,
    link: "https://www.facebook.com/rubiq.socialclub",
  },
  {
    image: partner9,
    link: "https://www.facebook.com/muzalporcelain",
  },
  {
    image: partner10,
    link: "http://www.szikszai.ro",
  },
  {
    image: partner11,
    link: "https://www.facebook.com/henterhunorfoto",
  },
  {
    image: partner12,
    link: "https://amigo-online.ro/hu",
  },
];

export const separetedPartners = [
  {
    image: sPartner1,
    link: "",
  },
  {
    image: sPartner2,
    link: "",
  },
];
