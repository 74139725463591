import { Box, styled } from "@mui/material";

export const StyledWrapper = styled(Box)(({ theme }) => ({
  WebkitTransform: "translate3d(0, 0, 0)",
  transform: "translate3d(0, 0, 0)",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  "& img": {
    height: "100px",
  },
  [theme.breakpoints.down("xl")]: {
    "& img": {
      height: "80px",
    },
  },
  [theme.breakpoints.down("xs")]: {
    "& img": {
      height: "60px",
    },
  },
  ".hero-title": {
    fontSize: "72px",
    lineHeight: "normal",
    fontStyle: "normal",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("lg")]: {
      fontSize: "60px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "52px",
      padding: "10px 0",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "36px",
    },
  },
  ".hero-subtitle": {
    fontSize: "131px",
    lineHeight: "normal",
    fontStyle: "normal",
    color: theme.palette.white.main,
    marginTop: "-30px",
    marginBottom: "33px",
    [theme.breakpoints.down("xl")]: {
      fontSize: "100px",
      marginTop: "-25px",
      marginBottom: "25px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "80px",
      marginTop: "-30px",
      marginBottom: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "80px",
      marginTop: "-20px",
      marginBottom: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "50px",
      marginTop: "-10px",
      marginBottom: "20px",
    },
  },
  ".hero-location-text": {
    fontSize: "32px",
    color: theme.palette.white.main,
    "& span": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "24px",
    },

    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
}));
