import { Container, styled } from "@mui/material";

const StyledWrapper = styled(Container)(({ theme }) => ({
  marginTop: "100px",
  maxWidth: theme.breakpoints.values.lg,
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: "120px",
  padding: "0px !important",
  [theme.breakpoints.down("xl")]: {
    maxWidth: theme.breakpoints.values.md,
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: theme.breakpoints.values.sm,
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    marginTop: "40px",
    marginBottom: "45px",
  },
  ".timetable-header-title": {
    textTransform: "uppercase",
  },
  ".timetable-box": {
    padding: "85px 162px",
    boxShadow: "0px 4px 25px 0px #00000059",
    borderRadius: "25px",
    [theme.breakpoints.down("xl")]: {
      padding: "40px 80px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px 23px",
    },
  },
  ".timetable-header-box": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "52px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "17px",
    },
    ".timetable-clock-icon": {
      width: "52px",
      height: "52px",
      [theme.breakpoints.down("sm")]: {
        width: "35px",
        height: "35px",
      },
    },
  },
  ".timetable-label-box": {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "28px",
    borderBottom: `3px solid ${theme.palette.common.black}`,
    [theme.breakpoints.down("lg")]: {
      paddingBottom: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "2px",
      borderBottom: `1px solid ${theme.palette.common.black}`,
    },
    ".text": {
      fontSize: "24px",
      lineHeight: "29px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "18px",
        lineHeight: "24px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        lineHeight: "14px",
      },
    },
  },
  ".timetable-list-box": {
    marginTop: "33px",
    [theme.breakpoints.down("md")]: {
      marginTop: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
  },

  ".timetable-mobile-section": {
    position: "relative",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "677px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ".overlay": {
      zIndex: 1,
      backgroundColor: "#EB0028",
      mixBlendMode: "multiply",
      position: "absolute",
      height: "100%",
      width: "100%",
    },
    ".timetable-mobile-container": {
      position: "relative",
      zIndex: 2,
      padding: "0px 12px !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "12px",
      ".arrow": {
        width: "40px",
        height: "40px",
        minWidth: "40px",
        borderRadius: "50%",
        backgroundColor: theme.palette.common.white,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("xs")]: {
          width: "22px",
          minWidth: "22px",
          height: "22px",
        },
        ".arrow-icon": {
          width: "16px",
          height: "16px",
          color: theme.palette.primary.main,
        },
      },
    },
    ".timetable-box-mobile": {
      backgroundColor: theme.palette.common.white,
      maxWidth: "308px",

      ".timetable-mobile-content-box": {
        padding: "16px 23px",
      },
    },
  },
}));

export default StyledWrapper;
