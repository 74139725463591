import { Box } from "@mui/material";
import { styled } from "@mui/system";
import bg from "../../assets/images/footer-bg.png";

export const StyledWrapper = styled(Box)(({ theme }) => ({
  backgroundImage: `url('${bg}')`,
  backgroundSize: "cover",
  position: "relative",
  ".footer-container": {
    position: "relative",
    zIndex: 2,
    maxWidth: theme.breakpoints.values.lg,
    margin: "0 auto",
    paddingTop: "100px",
    height: "100%",
  },
  ".menu-item-label": {
    color: theme.palette.white.main,
    fontSize: 24,
    padding: "5px 0",
    fontWeight: 600,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  ".slighter-p": {
    fontWeight: 400,
  },
  ".right-aligned-p": {
    textAlign: "right",
  },
  ".center-aligned-p": {
    textAlign: "center",
  },
  ".overlay": {
    zIndex: 1,
    backgroundColor: "#EB0028",
    mixBlendMode: "multiply",
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  ".social-link": {
    maxWidth: "100%",
    cursor: "pointer",
  },
  ".description": {
    fontSize: "18px",
    lineHeight: "26px",
    textAlign: "right",
    color: "#fff",
  },
  ".faq-container": {
    [theme.breakpoints.down("md")]: {
      paddingTop: "10px",
    },

    paddingBottom: "30px",
  },
  ".copyright-box": {
    zIndex: 2,
    position: "relative",
    paddingBottom: "20px",
    paddingTop: "20px",
    "& p": {
      textAlign: "center",
    },
  },
  ".border": {
    zIndex: 2,
    position: "relative",
    borderTop: "1px #fff solid",
  },
}));

export default StyledWrapper;
