import { Box } from "@mui/material";
import { styled } from "@mui/system";
import background from "../../../../assets/images/background.png";

export const StyleWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "100vh",
  width: "100%",
  top: "0px",
  overflow: "hidden",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundImage: `url(${background})`,
  marginTop: "150px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "50px",
  },
  ".hero-content-conainer": {
    position: "absolute",
    zIndex: "10",
    bottom: "20%",
    left: 0,
    right: 0,
  },
  ".x-container": {
    position: "absolute",
    right: 0,
    top: 0,
    height: "100%",
    zIndex: 10,
    mixBlendMode: "darken",
    WebkitTransform: "translate3d(0, 0, 0)",
    transform: "translate3d(0, 0, 0)",
    "& img": {
      height: "100%",
      fill: "#EB0028",
    },
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  ".video-container": {
    position: "absolute",
    zIndex: 9,
    mixBlendMode: "screen",
    aspectRatio: "9 / 16",
    WebkitTransform: "translate3d(0, 0, 0)",
    transform: "translate3d(0, 0, 0)",
    height: "100%",
    "& video": {
      height: "100%",
      mixBlendMode: "screen",
    },
  },
  ".left-side-ballon": {
    position: "absolute",
    top: "5%",
    left: "10%",
    WebkitTransform: "translate3d(0, 0, 0)",
    transform: "translate3d(0, 0, 0)",
    zIndex: 2,
    maxWidth: "77px",
    height: "107px",
    animation: "simpleMoving 7s infinite linear",
    "& img": {
      width: "100%",
      height: "auto",
    },
    [theme.breakpoints.down("xl")]: {
      top: "2%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ".middle-side-ballon": {
    position: "absolute",
    top: "14%",
    left: "50%",
    WebkitTransform: "translate3d(0, 0, 0)",
    transform: "translate3d(0, 0, 0)",
    zIndex: 2,
    maxWidth: "170px",
    height: "238px",
    animation: "simpleMoving 10s infinite linear",
    "& img": {
      width: "100%",
      height: "auto",
    },
    [theme.breakpoints.down("xl")]: {
      left: "35%",
      top: "8%",
    },
    [theme.breakpoints.down("lg")]: {
      left: "35%",
      top: "15%",
    },
    [theme.breakpoints.down("md")]: {
      left: "35%",
      top: "15%",
      maxWidth: "170px",
    },
    [theme.breakpoints.down("sm")]: {
      left: "10%",
      top: "15%",
      maxWidth: "120px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  ".right-side-ballon": {
    position: "absolute",
    top: "40%",
    right: "5%",
    WebkitTransform: "translate3d(0, 0, 0)",
    transform: "translate3d(0, 0, 0)",
    zIndex: 2,
    maxWidth: "77px",
    height: "107px",
    mixBlendMode: "darken",
    animation: "simpleMoving 15s infinite linear",
    "& img": {
      width: "100%",
      height: "auto",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      left: "10%",
      top: "15%",
    },
  },
  ".big-ballon-animation-box": {
    bottom: " 50%",
    right: "15%",
    position: "absolute",
    zIndex: 8,
    "& img": {
      maxWidth: "250px",
    },
    [theme.breakpoints.down("md")]: {
      right: "-5%",
      "& img": {
        maxWidth: "200px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& img": {
        maxWidth: "180px",
      },
    },
  },
}));
