import { FC, ReactNode, createContext, useEffect, useState } from "react";
import i18next from "i18next";

interface LanguageContextModel {
  language: string;
  setLanguage: (lang: string) => void;
}

interface Props {
  children: ReactNode;
}

export const LanguageContext = createContext<LanguageContextModel>({
  language: "hu",
} as LanguageContextModel);

export const LanguageContextProvider: FC<Props> = ({ children }) => {
  const [selectedLanguage, setselectedLanguage] = useState<string>(
    localStorage.getItem("lang")?.toString() || "hu"
  );

  useEffect(() => {
    if (selectedLanguage) {
      i18next.changeLanguage(selectedLanguage);
      localStorage.setItem("lang", selectedLanguage);
    }
  }, [selectedLanguage]);

  return (
    <LanguageContext.Provider
      value={{ language: selectedLanguage, setLanguage: setselectedLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
