import { Grid, IconButton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Menu } from "@mui/icons-material";
import xButton from "../../../../assets/images/close.svg";
import logo from "../../../../assets/images/tedx-logo.png";
import { StyledWrapper } from "./mobile-menu-bar.styles";
import CollapseMenu from "../collapse-menu/collapse-menu.component";

const MobileMenuBar: FC = () => {
  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const handleClose = () => {
    setMenuOpened(false);
  };
  const UpIcon = () => <img className="icon" alt="X" src={xButton} />;
  const MenuIcon = () => <Menu className="icon" />;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      handleClose();
    });
    return () => {
      window.removeEventListener("scroll", () => {
        handleClose();
      });
    };
  }, []);

  return (
    <>
      <StyledWrapper sx={{}}>
        <Grid container>
          <Grid className="logo-holder" item>
            <img src={logo} alt="logo" onClick={() => (window.location.href = `/`)} />
          </Grid>
          <Grid className="icon-holder" item xs>
            <IconButton
              className="icon-button"
              onClick={() => setMenuOpened((prev) => !prev)}
              disableTouchRipple
            >
              {menuOpened ? <UpIcon /> : <MenuIcon />}
            </IconButton>
          </Grid>
        </Grid>
      </StyledWrapper>
      <CollapseMenu
        open={menuOpened}
        timeout={0}
        unmountOnExit
        handleClose={handleClose}
      ></CollapseMenu>
    </>
  );
};

export default MobileMenuBar;
