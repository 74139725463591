import { FC } from "react";
import StyledWrapper from "./description.styles";

type Props = {
  text: string;
  className: string;
};

const Description: FC<Props> = ({ className, text }) => {
  return (
    <StyledWrapper variant="main" className={className}>
      {text}
    </StyledWrapper>
  );
};

export default Description;
