import { ThemeProvider } from "@mui/material";
import "./App.css";
import { theme } from "./utils/theme.util";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { router } from "./utils/routes.util";
import LanguageContextProvider from "./context/language.context";
import Header from "./common/header/header.component";
import Footer from "./common/footer/footer.component";
import React from "react";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LanguageContextProvider>
        <Header />
        <BrowserRouter>
          <Routes>
            {router.map((path, index) => (
              <Route key={index} path={path.path} element={path.element} />
            ))}
          </Routes>
        </BrowserRouter>
        <Footer />
      </LanguageContextProvider>
    </ThemeProvider>
  );
}

export default App;
