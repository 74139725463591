import { Box } from "@mui/material";
import { styled } from "@mui/system";
import background from "../../../../assets/images/about-background.png";

const StyledWrapper = styled(Box)(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundImage: `url(${background})`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "710px",
  [theme.breakpoints.down("sm")]: {
    minHeight: "500px",
    padding: "70px 30px",
  },
  [theme.breakpoints.down("sm")]: {
    minHeight: "500px",
    padding: "30px",
  },
  ".about-content-holder": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "80px",
    [theme.breakpoints.down("xl")]: {
      gap: "60px",
    },
    [theme.breakpoints.down("md")]: {
      gap: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      gap: "20px",
    },
  },
  ".about-title-box": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "8px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  ".about-title": {},
  ".about-description": {
    textAlign: "center",
    color: theme.palette.common.black,
    [theme.breakpoints.down("sm")]: {
      textAlign: "start",
    },
  },
}));

export default StyledWrapper;
