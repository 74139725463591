import { animated, useSpring } from "react-spring";
import ballonImage from "../../../../../assets/images/big-ballon.png";
import { useState } from "react";

const BigBallonAnimatedBox = () => {
  const [slideInFinished, setSlideInFinished] = useState(false);

  const slideInAnimation = useSpring({
    from: { bottom: "-20%" },
    to: { bottom: "50%" },
    config: { duration: 20000 },
    onRest: () => {
      setSlideInFinished(true);
    },
  });

  const bigBalloonMovingAnimation = useSpring({
    from: { transform: " rotate(0deg)" },
    to: [
      { transform: " rotate(5deg)" },
      { transform: " rotate(0deg)" },
      { transform: "rotate(-5deg)" },
      { transform: " rotate(0deg)" },
    ],
    reset: true,
    loop: true,
    config: { duration: 5000 },
  });

  return (
    <animated.div
      style={slideInFinished ? bigBalloonMovingAnimation : slideInAnimation}
      className="big-ballon-animation-box"
    >
      <img src={ballonImage} alt="ballon" />
    </animated.div>
  );
};

export default BigBallonAnimatedBox;
