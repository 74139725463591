import React from "react";
import { useTranslation } from "react-i18next";
import StyledWrapper from "./speakers-overlay-section.styles";
import { Box } from "@mui/system";
import Title from "../../../../common/title/title.components";
import Description from "../../../../common/description/description.components";
import { Container } from "@mui/material";

const SpeakersOverlaySection = () => {
  const { t } = useTranslation();
  return (
    <StyledWrapper id="speakers">
      <Box className="overlay"></Box>
      <Box className="speakers-content">
        <Container className="speakers-content-container">
          <Title className="speakers-title" text={t("speakers.label")} />
          <Description className="speakers-description" text={t("speakers.description")} />
        </Container>
      </Box>
    </StyledWrapper>
  );
};

export default SpeakersOverlaySection;
