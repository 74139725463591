import ArrowBackIosNewTwoToneIcon from "@mui/icons-material/ArrowBackIosNewTwoTone";
import ArrowForwardIosTwoToneIcon from "@mui/icons-material/ArrowForwardIosTwoTone";
import { Box } from "@mui/material";
import { FC, useMemo, useState } from "react";
import Ballon from "../../../../../assets/images/common-background.jpg";
import { TimetableModel } from "../../../../../types/timetable-type";
import TimetableHeader from "./timetable-header/timetable-header.component";
import TimetableLabel from "./timetable-label-box/timetable-label.components";
import TimetableListBox from "./timetable-list-box/timetable-list-box.component";

type Props = {
  timetableData: TimetableModel;
};
const TimeTableMobileBox: FC<Props> = ({ timetableData }) => {
  const [fromIndex, setFromIndex] = useState(0);
  const timetableSpeakersList = useMemo(() => {
    return timetableData.speakers;
  }, [timetableData.speakers]);

  const visibleSpeakers = useMemo(() => {
    return timetableSpeakersList.slice(fromIndex, fromIndex + 5);
  }, [timetableSpeakersList, fromIndex]);
  console.log(fromIndex);

  const handleRightClick = () => {
    if (fromIndex >= timetableSpeakersList.length - 5) {
      setFromIndex(0);
    } else setFromIndex((prev) => prev + 5);
  };

  const handleLeftClick = () => {
    if (fromIndex <= 0) {
      setFromIndex(timetableSpeakersList.length - 5);
    } else setFromIndex((prev) => prev - 5);
  };
  return (
    <Box
      className="timetable-mobile-section"
      sx={{
        backgroundImage: `url(${Ballon})`,
      }}
    >
      <Box className="overlay"> </Box>
      <Box className="timetable-mobile-container">
        <Box className={`arrow`} onClick={handleLeftClick}>
          <ArrowBackIosNewTwoToneIcon className={`arrow-icon`} />
        </Box>
        <Box className="timetable-box-mobile">
          <Box className="timetable-mobile-content-box">
            <TimetableHeader tableName={timetableData.tableName} />
            <TimetableLabel />
            <TimetableListBox speakersList={visibleSpeakers} />
          </Box>
        </Box>
        <Box className={`arrow`} onClick={handleRightClick}>
          <ArrowForwardIosTwoToneIcon className={`arrow-icon`} />
        </Box>
      </Box>
    </Box>
  );
};

export default TimeTableMobileBox;
