import { useEffect } from "react";
import { useScrollToElement } from "../../hooks/useScrollToElement";
import AboutSection from "./components/about-section/about-section.components";
import AboutTedLastSection from "./components/about-ted-last-section/about-ted-last-section.component";
import AboutTed from "./components/about-ted-section/about-ted-section.components";
import HeroSection from "./components/hero-section/hero-section.component";
import Informations from "./components/informations-section/informations.component";
import OrganizersSection from "./components/organizers-section/organizers-section.component";
import SpeakersOverlaySection from "./components/speakers-overlay-section/speakers-overlay-section.components";
import SpeakersSection from "./components/speakers-section/speakers-section.components";
import TimetableSection from "./components/timetable-section/timetable-section.component";
import StyledWrapper from "./landing.styles";

const LandingPage = () => {
  const scrollToElement = useScrollToElement();

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetId = hash.substring(1);
      scrollToElement(targetId);
    }
  }, [scrollToElement]);

  return (
    <StyledWrapper>
      <HeroSection />
      <Informations />
      <AboutSection />
      <SpeakersOverlaySection />
      <SpeakersSection />
      <TimetableSection />
      <AboutTed />
      <OrganizersSection />
      <AboutTedLastSection />
    </StyledWrapper>
  );
};

export default LandingPage;
