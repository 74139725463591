import ArrowBackIosNewTwoToneIcon from "@mui/icons-material/ArrowBackIosNewTwoTone";
import ArrowForwardIosTwoToneIcon from "@mui/icons-material/ArrowForwardIosTwoTone";
import { Box } from "@mui/material";
import { FC, useState } from "react";
import { PersonItemModel } from "../../../types/person-item.type";
import PersonItem from "../person-item/person-item.component";
import StyledWrapper from "./person-mobile.styles";

type Props = {
  imgList: string[];
  personList: PersonItemModel[];
  integratedType: "speakers" | "organizer";
};

const PersonMobile: FC<Props> = ({ personList, imgList, integratedType }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleRightClick = () => {
    if (activeIndex === personList.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex((prev) => prev + 1);
    }
  };

  const handleLeftClick = () => {
    if (activeIndex === 0) {
      setActiveIndex(personList.length - 1);
    } else {
      setActiveIndex((prev) => prev - 1);
    }
  };

  return (
    <StyledWrapper>
      <Box className={`left-arrow ${integratedType}`} onClick={handleLeftClick}>
        <ArrowBackIosNewTwoToneIcon className={`arrow-icon ${integratedType}`} />
      </Box>
      <PersonItem
        personItem={personList[activeIndex]}
        imageSrc={imgList[activeIndex]}
        isMobile
        integratedType={integratedType}
      />
      <Box className={`right-arrow ${integratedType}`} onClick={handleRightClick}>
        <ArrowForwardIosTwoToneIcon className={`arrow-icon ${integratedType}`} />
      </Box>
    </StyledWrapper>
  );
};

export default PersonMobile;
