import { Container } from "@mui/material";
import { styled } from "@mui/system";

const StyledWrapper = styled(Container)(({ theme }) => ({
  marginTop: "200px",
  marginBottom: "130px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    margin: "50px auto",
    maxWidth: "500px",
  },
  ".about-title-box": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "8px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  ".about-ted-title": {
    textAlign: "center",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px",
    },
  },
  ".about-ted-description-holder": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    marginBottom: "110px",
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
      padding: "0 30px",
    },
  },
  ".about-description": {
    textAlign: "center",
  },
  ".image-box-holder": {
    position: "relative",
    height: "900px",
    maxWidth: theme.breakpoints.values.lg,
    width: "100%",
    [theme.breakpoints.down("xl")]: {
      maxWidth: theme.breakpoints.values.md,
      height: "500px",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  ".bottom-image": {
    position: "absolute",
    width: "916px",
    "& img": {
      width: "100%",
      boxShadow: "0px 4px 12px 0px #0000008C",
    },
    [theme.breakpoints.down("xl")]: {
      width: "700px",
    },
  },
  ".top-image": {
    position: "absolute",
    right: 0,
    top: "40%",
    width: "742px",
    "& img": {
      width: "100%",
      boxShadow: "0px 4px 12px 0px #0000008C",
    },
    [theme.breakpoints.down("xl")]: {
      width: "500px",
      top: "35%",
    },
  },
}));

export default StyledWrapper;
