import { Box, Grid, Typography } from "@mui/material";
import { FC } from "react";

type Props = {
  embedLink: string;
  name: string;
  subject: string;
};

const TalkItem: FC<Props> = ({ embedLink, name, subject }) => {
  return (
    <Grid item lg={4} sm={6} xxs={12} className="talk-grid-item">
      <Box className="talk-item-box">
        <iframe
          className="iframe"
          src={embedLink}
          title={subject + " | " + name}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
        <Box className="talker-texts">
          <Typography className="talker-name" variant="mainBlack">
            {name}
          </Typography>
          <Typography className="talker-subject" variant="mainBlack">
            {subject}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default TalkItem;
