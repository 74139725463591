import { MenuItem, Select, styled } from "@mui/material";

export const StyledWrapper = styled(Select)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 800,
  fontSize: 24,
  border: 0,
  ".MuiOutlinedInput-notchedOutline": { border: 0, padding: "0" },
  ".MuiSelect-select": {
    padding: 0,
    paddingRight: "0px!important",
  },
  ":before": {
    content: '"|"',
  },
  ":focus-visible": {
    outline: "unset",
  },
  [theme.breakpoints.down("md")]: {
    fontWeight: 500,
    fontSize: 16,
  },
}));

export const StyledSelectMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 800,
  fontSize: 24,
  ":before": {
    content: '"|"',
  },
  [theme.breakpoints.down("md")]: {
    fontWeight: 500,
    fontSize: 16,
    ":before": {
      content: '"|"',
    },
  },
}));
