import { Box, styled } from "@mui/material";
import mobileBackground from "../../../../assets/images/common-background.jpg";

const StyledWrapper = styled(Box)(({ theme }) => ({
  maxWidth: theme.breakpoints.values.lg,
  marginTop: "135px",
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: "120px",
  [theme.breakpoints.down("xl")]: {
    maxWidth: theme.breakpoints.values.md,
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: theme.breakpoints.values.sm,
  },
  [theme.breakpoints.down("sm")]: {
    position: "relative",
    width: "100%",
    height: "523px",
    marginTop: "57px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundImage: `url(${mobileBackground})`,
    marginBottom: "45px",
  },
  ".overlay": {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      zIndex: 0,
      height: "100%",
      width: "100%",
      display: "block",
      backgroundColor: "#EB0028",
      mixBlendMode: "multiply",
    },
  },
  ".organizers-content": {
    height: "100%",
    position: "relative",
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "center",
    },
  },
  ".organizers-title": {
    color: theme.palette.primary.main,
    textAlign: "center",
    marginBottom: "75px",
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.white.main,
      marginTop: "30px",
      marginBottom: "0",
    },
  },
  ".organizers-highlighted": {
    display: "flex",
    justifyContent: "center",
    marginBottom: "65px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default StyledWrapper;
