import React from "react";
import { Box, Container, Typography, List, ListItem, ListItemText, Link } from "@mui/material";
import StyledWrapper from "./gdpr.style";

const GdprPage = () => {
  return (
    <StyledWrapper>
      <Container>
        <Box className="gdpr-container">
          <section>
            <Typography>
              <strong>
                Pe lângă activele noastre, datele noastre sunt, de asemenea, din ce în ce mai
                valoroase, așa că nu vrem să abuzăm de ele.
              </strong>
            </Typography>
            <Typography>
              <br />
              <strong>1. Ce sunt datele cu caracter personal?</strong>
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  Datele cu caracter personal înseamnă orice informație ce poate fi legată de o
                  persoană fizică identificată sau identificabilă, incluzând toate informațiile
                  directe sau indirecte ce se referă la persoană, cum ar fi: nume, prenume, adresă
                  de email, număr de telefon, IP, etc.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Prelucrăm datele tale cu caracter personal în conformitate cu prevederile
                  Regulamentului General de Protecție a Datelor (GDPR) – Regulament (UE) 2016/679
                  care acordă protecție persoanelor fizice prin conferirea de drepturi în acest
                  context.
                </ListItemText>
              </ListItem>
            </List>
            <Typography>
              <br />
              <strong>2. Care sunt drepturile tale?</strong>
            </Typography>
            <List>
              <ListItem>
                Dreptul de a fi informat cu privire la modul în care sunt colectate și folosite
                datele tale
              </ListItem>
              <ListItem>Dreptul de a accesa datele tale</ListItem>
              <ListItem>Dreptul de a cere și de a obține rectificarea datelor tale</ListItem>
              <ListItem>Dreptul de a cere și de a obține ștergerea datelor tale</ListItem>
              <ListItem>
                Dreptul de a cere și de a obține restricționarea prelucrării datelor tale
              </ListItem>
              <ListItem>
                Dreptul de a se adresa organelor abilitate în legătură cu folosirea datelor tale
              </ListItem>
            </List>
            <Typography>
              <br />
              Ce fe de date prelucrăm de la tine?
            </Typography>
            <List>
              <Typography>
                <strong>3. Date preluate în mod automat – Cookies</strong>
              </Typography>
              <ListItem>
                <List className="nestedList">
                  <ListItem>
                    Utilizăm cookie-uri pentru a putea face o analiză a navigării pe site-ul nostru,
                    pentru a administra site-ul și a colecta date despre utilizatori. Ne folosim de
                    aceste date pentru a personaliza și a îmbunătăți experiența ta în timpul
                    petrecut pe site.
                  </ListItem>
                  <ListItem>
                    <p>
                      Poți elimina modulele cookie stocate pe computerul tău prin setările
                      browserului pe care-l folosești. Mai multe informații despre cookie-uri poți
                      afla pe{" "}
                      <Link
                        href="https://www.allaboutcookies.org"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        www.allaboutcookies.org
                      </Link>
                      .
                    </p>
                  </ListItem>
                  <ListItem>
                    <p>
                      Pentru măsurarea traficului de pe site-ul nostru folosim Google Analytics care
                      are propria politică de confidențialitate pe care o poți vizualiza &nbsp;
                      <Link
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        aici
                      </Link>
                      .
                    </p>
                  </ListItem>
                </List>
              </ListItem>
              <Typography>
                <strong>4. Date pe care ni le furnizezi</strong>
              </Typography>
              <ListItem>
                <List className="nestedList">
                  <ListItem>
                    Date pe care le furnizezi în momentul înregistrării la eveniment: nume, prenume
                    și adresa ta de email pe care le prelucrăm în scopuri organizatorice, pentru a
                    facilita accesul tău la eveniment.
                  </ListItem>
                  <ListItem>
                    Date pe care le furnizezi în momentul abonării la newsletter-ul nostru: tot
                    numele, prenumele și adresa ta de email pe care le folosim în scopul de a-ți
                    trimite email-uri cu următoarele informații:
                  </ListItem>
                  <List className="nestedList">
                    <ListItem>&#x2022; informații despre evenimentul nostru;</ListItem>
                    <ListItem>&#x2022; informații despre speakerii evenimentului nostru;</ListItem>
                  </List>
                </List>
              </ListItem>
            </List>
            <Typography>
              <strong>5. Achiziționarea biletelor</strong>
            </Typography>{" "}
            <br />
            <Typography>
              Achiziționarea biletelor se face online, prin platforma Bileta.ro care are propria
              politică de confidențialitate pe care o poți vizualiza pe site.
            </Typography>
            <Typography>
              Te rugăm să verifici periodic site-ul nostru pentru a fi informat despre noile
              modificări, practici aduse Politicii noastre de confidențialitate, care devin valabile
              din momentul afișării lor pe site-ul nostru (
              <Link href="https://www.tedxcsikszereda.ro" target="_blank" rel="noopener noreferrer">
                www.tedxcsikszereda.ro
              </Link>
              ).
            </Typography>{" "}
            <br />
            <Typography>
              <strong>6. Date de contact:</strong>{" "}
              <Link href="mailto:event@tedxcsikszereda.ro">event@tedxcsikszereda.ro</Link>
            </Typography>
          </section>
        </Box>
      </Container>
    </StyledWrapper>
  );
};

export default GdprPage;
