import { AppBar } from "@mui/material";
import { styled } from "@mui/system";

export const StyledWrapper = styled(AppBar)(({ theme }) => ({
  position: "fixed",
  boxShadow: "none",
  height: 150,
  justifyContent: "center",
  paddingLeft: "0 !important",
  paddingRight: "0 !important",
  backgroundImage: "none",
  color: "white",
  backgroundColor: theme.palette.common.black,
  [theme.breakpoints.down("sm")]: {
    height: 50,
  },
  ".mobile-toolbar": {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  ".desktop-toolbar": {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default StyledWrapper;
