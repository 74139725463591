import React, { FC } from "react";
import { PersonItemModel } from "../../types/person-item.type";
import StyledWrapper from "./persons.styled";
import SpeakerItem from "./person-item/person-item.component";
import { useMediaQuery } from "@mui/system";
import { theme } from "../../utils/theme.util";
import PersonMobile from "./person-mobile/person-mobile.component";

type Props = {
  imgList: string[];
  personList: PersonItemModel[];
  integratedType: "speakers" | "organizer";
};

const Persons: FC<Props> = ({ imgList, personList, integratedType }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledWrapper container>
      {!isMobile ? (
        personList.map((personItem, index) => (
          <SpeakerItem
            imageSrc={imgList[index]}
            personItem={personItem}
            key={index}
            integratedType="speakers"
          />
        ))
      ) : (
        <PersonMobile imgList={imgList} personList={personList} integratedType={integratedType} />
      )}
    </StyledWrapper>
  );
};

export default Persons;
