import { Container } from "@mui/material";
import { styled } from "@mui/system";

export const StyledWrapper = styled(Container)(({ theme }) => ({
  paddingRight: "6.5px !important",
  ".logo-holder": {
    width: "126px",
    display: "flex",
    alignItems: "center",
    "& img": {
      width: "100%",
    },
  },
  ".icon-holder": {
    display: "flex",
    justifyContent: "flex-end",
    transition: "none",
    flexGrow: 1,
  },
  ".icon-button": {
    "&:hover": {
      backgroundColor: "transparent",
    },
    ".icon": {
      width: "20px",
      height: "20px",
      color: theme.palette.white.main,
    },
  },
}));
