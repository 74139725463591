import { Container, styled } from "@mui/material";

const StyledWrapper = styled(Container)(({ theme }) => ({
  marginTop: "100px",
  maxWidth: theme.breakpoints.values.lg,
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: "120px",
  padding: "0px !important",
  [theme.breakpoints.down("xl")]: {
    maxWidth: theme.breakpoints.values.md,
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: theme.breakpoints.values.sm,
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
    marginTop: "40px",
    marginBottom: "45px",
  },
}));

export default StyledWrapper;
