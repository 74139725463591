import { useMediaQuery } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TimetableModel } from "../../../../types/timetable-type";
import { theme } from "../../../../utils/theme.util";
import TimetableBox from "./timetable-box/timetable-box.component";
import TimeTableMobileBox from "./timetable-box/timetable-mobile-box.component";
import StyledWrapper from "./timetable-section.styles";

const TimetableSection: FC = () => {
  const { t } = useTranslation();
  const timetableData = t("timetable", { returnObjects: true }) as TimetableModel;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledWrapper id="timetable">
      {!isMobile ? (
        <TimetableBox timetableData={timetableData} />
      ) : (
        <TimeTableMobileBox timetableData={timetableData} />
      )}
    </StyledWrapper>
  );
};

export default TimetableSection;
