import { Box, styled } from "@mui/material";

export const StyledWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "33px",
  marginBottom: "34px",
  [theme.breakpoints.down("xl")]: {
    gap: "28px",
    marginBottom: "30px",
  },
  [theme.breakpoints.down("md")]: {
    gap: "18px",
  },
  [theme.breakpoints.down("sm")]: {
    gap: "18px",
    marginBottom: "30px",
  },
  [theme.breakpoints.down("xs")]: {
    gap: "14px",
  },
  ".count-down-box": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "7px",
  },
  ".count-down-timer": {
    width: "83px",
    height: "83px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "48.016px",
    fontStyle: "normal",
    lineHeight: "normal",
    borderRadius: "15.351px",
    border: "4.005px solid rgba(255, 255, 255, 0.74)",
    mixBlendMode: "lighten",
    background: "#00000069",
    boxShadow: "0px 0px 10px rgba(255, 255, 255, 0.52)",
    color: "#FFF",
    [theme.breakpoints.down("xl")]: {
      width: "66px",
      height: "66px",
      fontSize: "36px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50px",
      height: "50px",
      fontSize: "21px",
      borderRadius: "7px",
      border: "2px solid #FFF ",
    },
    [theme.breakpoints.down("xs")]: {
      width: "40px",
      height: "40px",
      fontSize: "16px",
      borderRadius: "7px",
      border: "2px solid #FFF ",
    },
  },
  ".count-down-label": {
    textAlign: "center",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "18.689px",
    fontStyle: "normal",
    lineHeight: "normal",
    textTransform: "uppercase",
    color: "#FFF",
    [theme.breakpoints.down("xl")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px",
    },
  },
}));
