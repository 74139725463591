import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import Title from "../../common/title/title.components";
import StyledWrapper from "./partners.styles";

import {
  mainPartners,
  partners,
  separetedPartners,
  specialPartners,
} from "../../constant/partners.constant";
import PartnersGrid from "./components/partners-grid.components";

const PartnersPage = () => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      <Container className="partners-content-holder">
        <Title className="partners-title" text={t("PartnersPage.title")} />
        <PartnersGrid
          list={mainPartners}
          style={{ spacing: 7, xl: 3, lg: 3, md: 6, sm: 12, xs: 12 }}
          title={t("PartnersPage.mainPartners")}
        />
        <PartnersGrid
          list={specialPartners}
          style={{ spacing: 4, xl: 2, lg: 2, md: 4, sm: 6, xs: 6 }}
          title={t("PartnersPage.specialPartners")}
        />
        <PartnersGrid
          list={partners}
          style={{ spacing: 2.5, xl: 1.8, lg: 1.8, md: 3, sm: 4, xs: 4 }}
          title={t("PartnersPage.partners")}
        />
        <PartnersGrid
          list={separetedPartners}
          style={{ spacing: 2.5, xl: 3, lg: 3, md: 4, sm: 5, xs: 7 }}
          title={t("")}
        />
      </Container>
    </StyledWrapper>
  );
};

export default PartnersPage;
