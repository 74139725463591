import { SelectChangeEvent, useTheme } from "@mui/material";
import { useState } from "react";
import { useLanguageContext } from "../../../../hooks/useContext.hook";
import { StyledSelectMenuItem, StyledWrapper } from "./language-selector.style";

const languages = ["en", "ro", "hu"];

const LanguageSelector = () => {
  const { language, setLanguage } = useLanguageContext();
  const [languageValue, setLanguageValue] = useState<string>(language);
  const theme = useTheme(); // Ensure you have access to the MUI theme

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setLanguageValue(event.target.value as string);
    setLanguage(event.target.value as string);
  };

  return (
    <StyledWrapper
      value={languageValue}
      onChange={handleChange}
      color="primary"
      MenuProps={{
        PaperProps: {
          sx: {
            backgroundColor: "black",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              left: "0 !important",
              maxWidth: "100% !important",
            },
            "& li": {
              [theme.breakpoints.down("sm")]: {
                marginLeft: "10px",
              },
            },
          },
        },
      }}
      IconComponent={() => null}
    >
      {languages.map((item, index) => (
        <StyledSelectMenuItem
          className="language-menu-item"
          key={index}
          value={item}
          sx={{ display: language === item ? "none" : "block" }}
        >
          {item.toLocaleUpperCase()}
        </StyledSelectMenuItem>
      ))}
    </StyledWrapper>
  );
};

export default LanguageSelector;
