import { Grid, styled } from "@mui/material";

const StyledWrapper = styled(Grid)(({ theme }) => ({
  width: "100%",
  paddingBottom: "22px",
  borderBottom: `1px solid ${theme.palette.common.black}`,
  textTransform: "uppercase",
  fontSize: "32px",
  lineHeight: "39px",
  [theme.breakpoints.down("xl")]: {
    fontSize: "30px",
    lineHeight: "36px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "28px",
    lineHeight: "32px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
    lineHeight: "22px",
    paddingBottom: "8px",
  },
  [theme.breakpoints.down("sm")]: {
    borderBottom: `0.5px solid ${theme.palette.common.black}`,
    fontSize: "14px",
    lineHeight: "17px",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "12px",
    lineHeight: "15px",
  },
  ".timetable-speaker-name-grid": {
    marginBottom: "30px",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "20px",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "5px",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px",
    },
    ".timetable-speaker-name": {
      color: theme.palette.common.black,
    },
  },
  ".timetable-speaker-theme-name": {
    color: theme.palette.primary.main,
  },
  ".timetable-speaker-time-grid": {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    ".timetable-speaker-time": {
      color: theme.palette.common.black,
      float: "right",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        textAlign: "end",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "8px",
      },
    },
  },
}));

export default StyledWrapper;
