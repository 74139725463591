import { Toolbar } from "@mui/material";
import DesktopMenuBar from "./components/desktop-menu-bar/desktop-menu-bar.component";
import MobileMenuBar from "./components/mobile-menu-bar/mobile-menu-bar.component";
import StyledWrapper from "./header.styles";

const Header = () => {
  return (
    <StyledWrapper>
      <Toolbar className="mobile-toolbar">
        <MobileMenuBar />
      </Toolbar>
      <Toolbar className="desktop-toolbar">
        <DesktopMenuBar />
      </Toolbar>
    </StyledWrapper>
  );
};

export default Header;
