import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import logo from "../../../../assets/images/tedx-logo.png";
import { MenuItemModel } from "../../../../types/menu-item.type";
import LanguageSelector from "../language-selector/language-selector.component";
import MenuItem from "../menu-item/menu-item.component";
import { StyledWrapper } from "./desktop-menu-bar.styles";

const DesktopMenuBar = () => {
  const { t } = useTranslation();
  const menuItems = t("header.menuPoints", { returnObjects: true }) as MenuItemModel[];

  return (
    <StyledWrapper>
      <Box className="logo-holder">
        <img src={logo} alt="logo" onClick={() => (window.location.href = `/`)} />
      </Box>
      {menuItems.map((item, index) => (
        <MenuItem label={item.label} link={item.link} id={item.id} key={`desktop-${index}`} />
      ))}
      <LanguageSelector />
    </StyledWrapper>
  );
};

export default DesktopMenuBar;
