import { Box, Typography } from "@mui/material";
import { FC, useState } from "react";
import { PersonItemModel } from "../../../types/person-item.type";
import StyledWrapper from "./person-item.styles";
import PersonPopover from "../person-popover/person-popover.component";

type Props = {
  imageSrc: string;
  personItem: PersonItemModel;
  isMobile?: boolean;
  integratedType: "speakers" | "organizer";
};

const PersonItem: FC<Props> = ({ imageSrc, personItem, isMobile, integratedType }) => {
  const [isOpen, setisOpen] = useState(false);

  const handleClick = () => {
    if (personItem.hasDetails) setisOpen(true);
  };

  const handleClose = () => {
    setisOpen(false);
  };

  return (
    <StyledWrapper item onMouseEnter={handleClick} onMouseLeave={handleClose}>
      <Box
        className={`person-image ${isOpen ? "active-popover" : ""}`}
        sx={{ backgroundImage: `url(${imageSrc})` }}
      >
        {personItem.hasDetails && (
          <Box
            className={`person-overlay-box ${isOpen ? "active-popover" : ""}`}
            onClick={handleClick}
          ></Box>
        )}
      </Box>
      <Box className="person-content-holder">
        <Box className="person-name-holder">
          <Typography className={`person-firstname ${integratedType}`} variant="mainBlack">
            {personItem.firstName}
          </Typography>
          {personItem.lastName && (
            <Typography className={`person-lastname ${integratedType}`} variant="mainBlack">
              {personItem.lastName}
            </Typography>
          )}
        </Box>
        <Typography className={`person-role ${integratedType}`} variant="main">
          {personItem.role}
        </Typography>
      </Box>
      <PersonPopover
        open={isOpen}
        description={personItem.description}
        firstName={personItem.firstName}
        lastName={personItem.lastName}
        role={personItem.role}
        isMobile={isMobile}
        handleClose={handleClose}
        integratedType={integratedType}
      />
    </StyledWrapper>
  );
};

export default PersonItem;
