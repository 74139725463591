import { Grid } from "@mui/material";
import { talks } from "../../../constant/talks.constant";
import TalkItem from "./talk-item.component";

const TalksGrid = () => {
  return (
    <Grid container className="talks-grid-container">
      {talks.map((talk, index) => (
        <TalkItem key={index} embedLink={talk.embedLink} name={talk.name} subject={talk.subject} />
      ))}
    </Grid>
  );
};

export default TalksGrid;
