import { Box } from "@mui/material";
import { styled } from "@mui/system";
import background from "../../../../assets/images/common-background.jpg";

const StyledWrapper = styled(Box)(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundImage: `url(${background})`,
  height: "590px",
  width: "100%",
  position: "relative",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    backgroundImage: "unset",
    height: "unset",
    marginTop: "70px",
  },
  ".overlay": {
    position: "absolute",
    zIndex: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    backgroundColor: "#EB0028",
    mixBlendMode: "multiply",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ".about-tedx-content": {
    position: "relative",
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      paddingBottom: "30px",
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  ".about-tedx-content-container": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
  },
  ".about-tedx-title": {
    textAlign: "center",
    color: theme.palette.white.main,
    [theme.breakpoints.down("sm")]: {
      textAlign: "start",
      color: theme.palette.common.black,
    },
  },
  ".about-tedx-subtitle": {
    fontSize: "24px",
    lineHeight: "36px",
    textAlign: "center",
    color: theme.palette.white.main,
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "start",
      color: theme.palette.common.black,
      fontSize: "16px",
      lineHeight: "22px",
      marginBottom: "20px",
    },
  },
  ".about-tedx-description": {
    textAlign: "center",
    color: theme.palette.white.main,
    [theme.breakpoints.down("sm")]: {
      textAlign: "start",
      color: theme.palette.common.black,
    },
  },
}));

export default StyledWrapper;
