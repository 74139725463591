import { Box, styled } from "@mui/material";
import backgroundDesktop from "../../../../assets/images/super-hero-desktop.jpg";
import backgroundMobile from "../../../../assets/images/super-hero-mobile.jpg";

const StyledWrapper = styled(Box)(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundImage: `url(${backgroundDesktop})`,
  maxHeight: "900px",
  height: "900px",
  width: "100%",
  position: "relative",
  display: "flex",
  alignItems: "flex-end",
  [theme.breakpoints.down("sm")]: {
    backgroundImage: `url(${backgroundMobile})`,
    height: "400px",
    backgroundPosition: "top",
  },
  [theme.breakpoints.down("xs")]: {
    backgroundImage: `url(${backgroundMobile})`,
    height: "450px",
    backgroundPosition: "top",
  },
  ".overlay": {
    position: "absolute",
    zIndex: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    backgroundColor: "#EB0028",
    mixBlendMode: "multiply",
  },
  ".speakers-content": {
    position: "relative",
    zIndex: 1,
    paddingBottom: "100px",

    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      gap: "12px",
      paddingBottom: "30px",
      paddingLeft: "30px",
      paddingRight: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      gap: "12px",
      paddingBottom: "30px",
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  ".speakers-content-container": {
    margin: "auto",
    display: "flex",
    gap: "46px",
    flexDirection: "column",
    justifyContent: "center",
  },
  ".speakers-title": {
    textAlign: "center",
    color: theme.palette.white.main,
  },
  ".speakers-description": {
    textAlign: "center",
    color: theme.palette.white.main,
  },
}));

export default StyledWrapper;
