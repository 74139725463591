import LandingPage from "../pages/landing-page/landing.page";
import GdprPage from "../pages/gdpr-page/gdpr.page";
import FAQ from "../pages/faq/faq.page";
import PartnersPage from "../pages/partners-page/partners.page";
import TalksPage from "../pages/talks/talks.page";

export const router = [
  {
    element: <LandingPage />,
    path: "/",
  },
  {
    element: <PartnersPage />,
    path: "/partners",
  },
  {
    element: <GdprPage />,
    path: "/gdpr",
  },
  {
    element: <FAQ />,
    path: "/faq",
  },
  {
    element: <TalksPage />,
    path: "/tedx-talks",
  },
];
