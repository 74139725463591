import { Box, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Persons from "../../../../common/persons/persons.component";
import Title from "../../../../common/title/title.components";
import {
  organizerDesktopImages,
  organizersMobileImages,
  highlightedMember,
} from "../../../../constant/organizers-images.constant";
import { PersonItemModel } from "../../../../types/person-item.type";
import StyledWrapper from "./organizers-section.styles";
import PersonItem from "../../../../common/persons/person-item/person-item.component";
import { theme } from "../../../../utils/theme.util";

const OrganizersSection = () => {
  const { t } = useTranslation();
  const organizers = t("organizers.organizersList", { returnObjects: true }) as PersonItemModel[];
  const fullList = [...organizers];
  const firstItem = organizers.shift();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledWrapper>
      <Box className="overlay"></Box>
      <Box className="organizers-content">
        <Title className="organizers-title" text={t("organizers.title")} />
        {!isMobile && firstItem && (
          <Box className="organizers-highlighted">
            <PersonItem
              imageSrc={highlightedMember}
              integratedType="organizer"
              personItem={firstItem}
            />
          </Box>
        )}
        <Persons
          imgList={isMobile ? organizersMobileImages : organizerDesktopImages}
          personList={isMobile ? fullList : organizers}
          integratedType="organizer"
        />
      </Box>
    </StyledWrapper>
  );
};

export default OrganizersSection;
