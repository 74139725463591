import { Box, styled } from "@mui/material";

const StyledWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  marginTop: "150px",
  backgroundColor: theme.palette.white.main,
  [theme.breakpoints.down("sm")]: {
    marginTop: "50px",
  },
}));

export default StyledWrapper;
