import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledWrapper = styled(Typography)(({ theme }) => ({
  fontSize: "60px",
  lineHeight: "72px",
  [theme.breakpoints.down("xl")]: {
    fontSize: "52px",
    lineHeight: "68px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "42px",
    lineHeight: "52px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "32px",
    lineHeight: "38px",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "26px",
    lineHeight: "32px",
  },
}));

export default StyledWrapper;
