import { Box, Container } from "@mui/material";
import xOverlayImage from "../../../../assets/images/X.svg";
import BallonImage from "../../../../assets/images/big-ballon.png";
import fogVideo from "../../../../assets/images/fog.mp4";
import { getMobileOS } from "../../../../utils/getMobileOs";
import BigBallonAnimatedBox from "./ballons/big-ballon-animated-box";
import HeroContents from "./hero-contents/hero-contents";
import { StyleWrapper } from "./hero-section.styles";

const HeroSection = () => {
  const os = getMobileOS();

  return (
    <StyleWrapper>
      <Container className="hero-content-conainer">
        <HeroContents />
      </Container>
      <Box className="left-side-ballon">
        <img src={BallonImage} alt="ballon-left" />
      </Box>
      <Box className="middle-side-ballon">
        <img src={BallonImage} alt="ballon-middle" />
      </Box>
      <Box className="right-side-ballon">
        <img src={BallonImage} alt="ballon-middle" />
      </Box>
      <BigBallonAnimatedBox />
      <Box className="x-container">
        <img alt="X" src={xOverlayImage} />
      </Box>
      {os !== "iOS" && (
        <Box className="video-container">
          <video autoPlay muted loop>
            <source src={fogVideo} type="video/mp4"></source>
          </video>
        </Box>
      )}
    </StyleWrapper>
  );
};

export default HeroSection;
