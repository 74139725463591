import React from "react";
import StyledWrapper from "./footer.styles";
import { Box, Container, Grid, Link, Typography, useMediaQuery } from "@mui/material";
import tedxLogo from "../../assets/images/tedx-logo-footer.png";
import fbLogo from "../../assets/images/socials/fb.png";
import instaLogo from "../../assets/images/socials/insta.png";
import { theme } from "../../utils/theme.util";
import MobileFooter from "./mobileFooter.component";
import { useTranslation } from "react-i18next";
import { MenuItemModel } from "../../types/menu-item.type";
import { useScrollToElement } from "../../hooks/useScrollToElement";

const LeftMenuItem: React.FC<{
  menuItem: { title: string; url: string; id: string };
  onClick: (id: string) => void;
}> = ({ menuItem, onClick }) => {
  return (
    <>
      {menuItem.url ? (
        <Link className="menu-item-link" href="/partners" sx={{ textDecoration: "none" }}>
          <Typography className="menu-item-label">{menuItem.title}</Typography>
        </Link>
      ) : (
        <Typography className="menu-item-label" onClick={() => onClick(menuItem.id)}>
          {menuItem.title}
        </Typography>
      )}
    </>
  );
};

const SocialSection = () => {
  const handleSocialClick = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <Grid container spacing={3} sx={{ maxWidth: "200px", mb: "30px" }}>
      <Grid item xs={4}></Grid>
      <Grid item xs={4}>
        <img
          src={fbLogo}
          alt="facebook"
          className="social-link"
          onClick={() => {
            handleSocialClick("https://www.facebook.com/tedxcsikszereda/");
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <img
          src={instaLogo}
          alt="instagram"
          className="social-link"
          onClick={() => {
            handleSocialClick("https://www.instagram.com/tedxcsikszereda/");
          }}
        />
      </Grid>
    </Grid>
  );
};

const CopyRightSection = () => {
  return (
    <Container className="copyright-box">
      <Typography className="description">
        Copyright © TEDxCsíkszereda 2024 | This independent TEDx event is operated under license
        from TED | Designed by Octopus | Developed by CamelCoding
      </Typography>
    </Container>
  );
};

const DesktopFooter = () => {
  const { t } = useTranslation();
  const menuItems = t("header.menuPoints", { returnObjects: true }) as MenuItemModel[];

  const handleUrlClick = (url: string) => {
    window.open(url, "_blank");
  };

  const handleClick = useScrollToElement();
  return (
    <StyledWrapper>
      <Box className="overlay"></Box>
      <Container className="footer-container">
        <Grid container>
          <Grid item xs={12} sm={3}>
            <img src={tedxLogo} alt="TEDx Logo" />
            {menuItems.map((menuItem, index) => {
              return (
                <LeftMenuItem
                  key={index}
                  menuItem={{ url: menuItem.link, title: menuItem.label, id: menuItem.id || "" }}
                  onClick={handleClick}
                />
              );
            })}
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid container alignItems="flex-end" flexDirection="column">
              <Grid item xs={4} lg={2}>
                <SocialSection />
              </Grid>
              <Grid item xs={9}>
                {/* <Description /> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="faq-container">
          <Grid item xs={3} lg={3}>
            <Typography
              className="menu-item-label left-aligned-p"
              onClick={() => (window.location.href = "/faq")}
            >
              {t("Footer.faq")}
            </Typography>
          </Grid>
          <Grid item xs={3} lg={3}>
            <Typography
              className="menu-item-label slighter-p left-aligned-p"
              onClick={() => (window.location.href = "/gdpr")}
            >
              GDPR
            </Typography>
          </Grid>
          <Grid item xs={3} lg={3}>
            <Typography
              className="menu-item-label slighter-p center-aligned-p"
              onClick={() =>
                handleUrlClick(
                  "https://www.ted.com/about/our-organization/our-policies-terms/ted-com-terms-of-use"
                )
              }
            >
              Terms of Use
            </Typography>
          </Grid>
          <Grid item xs={3} lg={3}>
            <Typography
              className="menu-item-label slighter-p right-aligned-p"
              onClick={() => {
                handleUrlClick(
                  "https://www.ted.com/about/our-organization/our-policies-terms/privacy-policy"
                );
              }}
            >
              Privacy Policy
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Box className="border"></Box>
      <CopyRightSection />
    </StyledWrapper>
  );
};

const Footer = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return <>{isMobile ? <MobileFooter /> : <DesktopFooter />}</>;
};

export default Footer;
