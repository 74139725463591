import { Box } from "@mui/material";
import { styled } from "@mui/system";
import bg from "../../assets/images/footer-bg.png";

export const StyledWrapper = styled(Box)(({ theme }) => ({
  backgroundImage: `url('${bg}')`,
  backgroundSize: "cover",
  position: "relative",
  ".footer-container": {
    position: "relative",
    zIndex: 2,
    maxWidth: theme.breakpoints.values.lg,
    margin: "0 auto",
    padding: "0 24px 0 24px",
    height: "100%",
  },
  ".mobile-black-container": {
    padding: "20px 24px",
    zIndex: "2",
    position: "relative",
    backgroundColor: "#000",
  },
  ".menu-item-link": {
    textDecoration: "none",
  },
  ".menu-item-label": {
    textDecoration: "none",
    fontSize: "10px",
    lineHeight: "16px",
    color: "#fff",
    textAlign: "center",
  },
  ".overlay": {
    zIndex: 1,
    backgroundColor: "#EB0028",
    mixBlendMode: "multiply",
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  ".copyright-box": {
    zIndex: 2,
    position: "relative",
    padding: "20px 0",
    "& p": {
      fontSize: "10px",
      lineHeight: "16px",
      textAlign: "center",
      color: "#fff",
    },
  },
}));

export default StyledWrapper;
