import { Collapse, styled } from "@mui/material";
import { theme } from "../../../../utils/theme.util";

const StyledWrapper = styled(Collapse)(() => ({
  position: "fixed",
  zIndex: "2222",
  width: "100%",
  top: "0px",
  marginTop: "50px",
  paddingTop: "10px",
  minHeight: "150px !important",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingBottom: "15px",
  backgroundColor: "#000000e6",
  borderBottom: 2,
  borderColor: "black",
  ".collapse-list": {
    width: "auto",
    padding: "0 6.5px",
    cursor: "pointer",
    fontSize: "14px",
    lineHeight: "22px",
    textTransform: "uppercase",
    display: "flex",
    gap: "10px",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingTop: "10px",
    [theme.breakpoints.down("sm")]: {
      height: "unset",
    },
  },
  ".menu-item-link": {
    color: theme.palette.white.main,
    textDecoration: "unset",
  },
  ".menu-item-label": {
    textTransform: "none",
  },
}));

export default StyledWrapper;
