import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Persons from "../../../../common/persons/persons.component";
import { SpeakersImages } from "../../../../constant/speakers-image.constant";
import { PersonItemModel } from "../../../../types/person-item.type";
import { theme } from "../../../../utils/theme.util";
import StyledWrapper from "./speakers-section.styles";

const SpeakersSection = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const speakers = t("speakers.speakersList", { returnObjects: true }) as PersonItemModel[];
  const sortedSepakers = speakers.filter((item) => item.hasDetails);

  return (
    <StyledWrapper>
      <Persons
        personList={isMobile ? sortedSepakers : speakers}
        imgList={SpeakersImages}
        integratedType="speakers"
      />
    </StyledWrapper>
  );
};

export default SpeakersSection;
