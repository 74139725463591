import React from "react";
import StyledWrapper from "./about-ted-section.styles";
import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Title from "../../../../common/title/title.components";
import Description from "../../../../common/description/description.components";

const AboutTed = () => {
  const { t } = useTranslation();
  return (
    <StyledWrapper>
      <Box className="overlay"></Box>
      <Container className="about-tedx-content-container">
        <Box className="about-tedx-content">
          <Title className="about-tedx-title" text={t("aboutTedx.title")} />
          <Typography variant="mainBlack" className="about-tedx-subtitle">
            {t("aboutTedx.subTitle")}
          </Typography>
          <Description className="about-tedx-description" text={t("aboutTedx.description")} />
        </Box>
      </Container>
    </StyledWrapper>
  );
};

export default AboutTed;
