import { Box, styled } from "@mui/material";
import backgroundImage from "../../assets/images/partners-background.png";
const StyledWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  ".partners-content-holder": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "150px",
    paddingLeft: "32px !important",
    paddingRight: "32px !important",
    paddingTop: "150px",
    paddingBottom: "150px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
      paddingTop: "64px",
      paddingBottom: "64px",
    },
  },
  ".partners-title": {
    color: theme.palette.primary.main,
    textAlign: "center",
    marginBottom: "40px",
  },
}));

export default StyledWrapper;
