import { Box, Typography } from "@mui/material";
import { FC } from "react";

type Props = {
  open: boolean;
  firstName: string;
  lastName: string;
  role: string;
  description: string;
  isMobile?: boolean;
  handleClose: () => void;
  integratedType: "speakers" | "organizer";
};

const PersonPopover: FC<Props> = ({
  open,
  firstName,
  lastName,
  role,
  description,
  isMobile,
  handleClose,
  integratedType,
}) => {
  const handleCloseOnMobile = () => {
    if (isMobile) {
      handleClose();
    }
  };
  return (
    <Box
      className={`popover ${isMobile ? integratedType : ""} ${open ? "active" : ""}`}
      onClick={handleCloseOnMobile}
    >
      <Box className={`popover-overlay  ${isMobile ? integratedType : ""}`}> </Box>
      <Box className="popover-text-box">
        <Box className="popover-person-name-holder">
          <Typography className="popover-name" variant="mainBlack">
            {firstName}
          </Typography>
          <Typography className="popover-name" variant="mainBlack">
            {lastName}
          </Typography>
        </Box>
        <Typography className="popover-role" variant="mainSemiBold">
          {role}
        </Typography>
        <Typography
          className={`popover-description ${isMobile ? integratedType : ""}`}
          variant="mainSemiBold"
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default PersonPopover;
