import { FC } from "react";
import StyledWrapper from "./title.styles";

type Props = {
  text: string;
  className: string;
  color?: string;
};

const Title: FC<Props> = ({ className, text, color }) => {
  return (
    <StyledWrapper variant="mainBlack" className={className} color={color}>
      {text}
    </StyledWrapper>
  );
};

export default Title;
