import { FC } from "react";
import { animated, useSpring } from "react-spring";

type Props = {
  value: number;
};

const AnimatedCounter: FC<Props> = ({ value }) => {
  const { count } = useSpring({
    from: { count: 0 },
    to: { count: value },
    config: { duration: 5000 },
  });

  return (
    <animated.div className="information-animated-box">
      {count.interpolate((val) => Math.floor(val))}
    </animated.div>
  );
};

export default AnimatedCounter;
