import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledWrapper = styled(Typography)(({ theme }) => ({
  whiteSpace: "break-spaces",
  fontSize: "24px",
  lineHeight: "36px",
  maxWidth: theme.breakpoints.values.lg,
  textAlign: "center",
  [theme.breakpoints.down("xl")]: {
    fontSize: "22px",
    lineHeight: "32px",
    maxWidth: theme.breakpoints.values.md,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
    lineHeight: "26px",
    maxWidth: theme.breakpoints.values.sm,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "start",
  },
}));

export default StyledWrapper;
