import { Box } from "@mui/material";
import React, { FC } from "react";
import Title from "../../../../../../common/title/title.components";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

type Props = {
  tableName: string;
};

const TimetableHeader: FC<Props> = ({ tableName }) => {
  return (
    <Box className="timetable-header-box">
      <AccessTimeIcon className="timetable-clock-icon" />
      <Title className="timetable-header-title" color="primary.main" text={tableName} />
    </Box>
  );
};

export default TimetableHeader;
