import { Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledWrapper = styled(Box)(({ theme }) => ({
  maxWidth: "1175px",
  marginBottom: "100px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  ".partners-subtitle": {
    textTransform: "uppercase",
    textAlign: "center",
    color: theme.palette.common.black,
    fontSize: "42px",
    marginBottom: "20px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "26px",
    },
  },
  ".partners-grid-container": {
    justifyContent: "center",
  },
  ".partners-grid-item": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: "100%",
    },
  },
  ".partners-grid-item.mobile-hide": {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default StyledWrapper;
