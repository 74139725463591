import React from "react";
import StyledWrapper from "./mobileFooter.styles";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const MobileFooter = () => {
  const { t } = useTranslation();

  const CopyRightSection = () => {
    return (
      <Container className="copyright-box">
        <Typography className="description">
          Copyright © TEDxCsíkszereda 2024| Designed by Octopus | Developed by CamelCoding <br />
          <Typography component={"span"} className="description" sx={{ opacity: 0.8 }}>
            {" "}
            This independent TEDx event is operated under license from TED
          </Typography>
        </Typography>
      </Container>
    );
  };
  const handleUrlClick = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <StyledWrapper>
      <Box className="overlay"></Box>
      <Box className="mobile-black-container">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={3}>
            <Typography onClick={() => (window.location.href = "/faq")} className="menu-item-label">
              {t("Footer.faq")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              onClick={() => (window.location.href = "/gdpr")}
              className="menu-item-label"
            >
              GDPR
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              onClick={() =>
                handleUrlClick(
                  "https://www.ted.com/about/our-organization/our-policies-terms/ted-com-terms-of-use"
                )
              }
              className="menu-item-label"
            >
              Terms of Use
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              onClick={() => {
                handleUrlClick(
                  "https://www.ted.com/about/our-organization/our-policies-terms/privacy-policy"
                );
              }}
              className="menu-item-label"
            >
              Privacy Policy
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className="footer-container">
        <CopyRightSection />
      </Box>
    </StyledWrapper>
  );
};

export default MobileFooter;
