import { Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  ".left-arrow": {
    cursor: "pointer",
    width: "40px",
    height: "40px",
    position: "absolute",
    top: "140px",
    left: "-40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    zIndex: 1,
    [theme.breakpoints.down("xs")]: {
      width: "30px",
      height: "30px",
      top: "100px",
      left: "-10px",
    },
  },
  ".right-arrow": {
    cursor: "pointer",
    width: "40px",
    height: "40px",
    position: "absolute",
    top: "140px",
    right: "-40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    zIndex: 1,
    [theme.breakpoints.down("xs")]: {
      width: "30px",
      height: "30px",
      top: "100px",
      right: "-10px",
    },
  },
  ".arrow-icon.speakers": {
    color: theme.palette.white.main,
  },

  ".left-arrow.speakers, .right-arrow.speakers": {
    backgroundColor: theme.palette.primary.main,
  },

  ".arrow-icon.organizer": {
    color: theme.palette.primary.main,
  },

  ".left-arrow.organizer, .right-arrow.organizer": {
    backgroundColor: theme.palette.white.main,
  },
}));

export default StyledWrapper;
