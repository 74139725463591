import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AnimatedCounter from "./animated-counter/animated-counter.component";
import StyledWrapper from "./informations.styles";

const Informations = () => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      <Container className="information-container">
        <Box className="information-box">
          <AnimatedCounter value={12} key={"speakers"} />
          <Typography className="information-label">{t("informations.speakers")}</Typography>
        </Box>
        <Box className="information-box">
          <AnimatedCounter value={12} key={"topics"} />
          <Typography className="information-label">{t("informations.topics")}</Typography>
        </Box>
        <Box className="information-box">
          <AnimatedCounter value={100} key={"tickets"} />
          <Typography className="information-label">{t("informations.tickets")}</Typography>
        </Box>
      </Container>
    </StyledWrapper>
  );
};

export default Informations;
