import { Link, Typography } from "@mui/material";
import { FC } from "react";
import { useScrollToElement } from "../../../../hooks/useScrollToElement";
type Props = {
  label: string;
  link?: string;
  id?: string;
  handleClose?: () => void;
};

const MenuItem: FC<Props> = ({ label, link, id, handleClose }) => {
  const handleClick = useScrollToElement();

  const handleMenuItemClick = (id: string) => {
    handleClick(id);
    handleClose?.();
  };
  return (
    <>
      {link ? (
        <Link className="menu-item-link" href={link}>
          <Typography className="menu-item-label">{label}</Typography>
        </Link>
      ) : (
        <Typography className="menu-item-label" onClick={() => id && handleMenuItemClick(id)}>
          {label}
        </Typography>
      )}
    </>
  );
};

export default MenuItem;
