import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { StyledWrapper } from "./counter-box.styles";

const CounterBox = () => {
  const calculateTimeLeft = () => {
    const targetDate = new Date("2024-04-20T10:00:00Z").getTime();
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference <= 0) {
      // If the target date has passed, set the timeLeft to zero
      return {
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
      };
    }

    const formatNumber = (num: number) => (num < 10 ? `0${num}` : `${num}`);

    const days = formatNumber(Math.floor(difference / (1000 * 60 * 60 * 24)));
    const hours = formatNumber(Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    const minutes = formatNumber(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)));
    const seconds = formatNumber(Math.floor((difference % (1000 * 60)) / 1000));

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear the interval when the component is unmounted or the target date is reached
    return () => clearInterval(timer);
  }, []);

  return (
    <StyledWrapper>
      <Box className="count-down-box">
        <Box className="count-down-timer">{timeLeft.days}</Box>
        <Box className="count-down-label">days</Box>
      </Box>
      <Box className="count-down-box">
        <Box className="count-down-timer">{timeLeft.hours}</Box>
        <Box className="count-down-label">hours</Box>
      </Box>
      <Box className="count-down-box">
        <Box className="count-down-timer">{timeLeft.minutes}</Box>
        <Box className="count-down-label">minutes</Box>
      </Box>
      <Box className="count-down-box">
        <Box className="count-down-timer">{timeLeft.seconds}</Box>
        <Box className="count-down-label">seconds</Box>
      </Box>
    </StyledWrapper>
  );
};

export default CounterBox;
