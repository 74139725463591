import { Box, styled } from "@mui/material";

const StyledWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  //   backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  ".talks-content-holder": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "150px",
    paddingTop: "150px",
    paddingBottom: "150px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
      paddingTop: "64px",
      paddingBottom: "64px",
    },
  },
  ".talks-title": {
    color: theme.palette.primary.main,
    textAlign: "center",
    marginBottom: "40px",
  },
  ".talks-grid-container": {
    ".talk-grid-item": {
      outline: "1px solid #e4e4e4",
      ".talk-item-box": {
        width: "calc(100% - 40px)",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        ".iframe": {
          width: "100%",
          height: "auto",
          aspectRatio: "1.7777",
        },
        ".talker-texts": {
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          ".talker-name": {
            color: theme.palette.primary.main,
            textTransform: "uppercase",
            fontSize: "28px",
            lineHeight: "39px",

            [theme.breakpoints.down("sm")]: {
              fontSize: "22px",
              lineHeight: "17px",
            },
          },
          ".talker-subject": {
            color: theme.palette.common.black,
            textTransform: "uppercase",
            fontSize: "22px",
            lineHeight: "33px",

            [theme.breakpoints.down("sm")]: {
              fontSize: "18px",
              lineHeight: "17px",
            },
          },
        },
      },
    },
  },
}));

export default StyledWrapper;
