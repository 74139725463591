import { Box, styled } from "@mui/material";

const StyledWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  marginTop: "150px",
  backgroundColor: theme.palette.white.main,
  "& *": {
    fontFamily: "Inter", // Replace 'Your Chosen Font' with the desired font family
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "50px",
  },
  ".gdpr-container": {
    padding: "30px 0",
  },
  "& strong": {
    fontWeight: "bold",
  },
  ".nestedList": {
    marginLeft: 0,
    "& li": {
      paddingLeft: 0,
    },
  },
}));

export default StyledWrapper;
