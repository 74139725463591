import { FC } from "react";
import StyledWrapper from "./timetable-item.styles";
import { Grid, Typography } from "@mui/material";

type Props = {
  name?: string;
  title: string;
  time: string;
};

const TimetableItem: FC<Props> = ({ name, title, time }) => {
  return (
    <StyledWrapper
      item
      container
      xxs={12}
      sx={{
        marginBottom: name
          ? { lg: "47px", md: "35px", sm: "25px", xxs: "10px" }
          : { lg: "100px", md: "60px", sm: "40px", xxs: "10px" },
      }}
    >
      {name && (
        <Grid item xxs={12} className="timetable-speaker-name-grid">
          <Typography className="timetable-speaker-name" variant="mainBold">
            {name}
          </Typography>
        </Grid>
      )}
      <Grid item xl={9.5} md={9} sm={9} xxs={8.5}>
        <Typography className="timetable-speaker-theme-name" variant="mainBlack">
          {title}
        </Typography>
      </Grid>
      <Grid item xl={2.5} md={3} sm={3} xxs={3.5} className="timetable-speaker-time-grid">
        <Typography className="timetable-speaker-time" variant="mainBold">
          {time}
        </Typography>
      </Grid>
    </StyledWrapper>
  );
};

export default TimetableItem;
