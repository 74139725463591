import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import Description from "../../../../common/description/description.components";
import Title from "../../../../common/title/title.components";
import StyledWrapper from "./about-section.styled";

type Title = {
  label: string;
  color: string;
};

const AboutSection = () => {
  const { t } = useTranslation();
  const titles = t("aboutTedxCsikszereda.title", { returnObjects: true }) as Title[];
  return (
    <StyledWrapper id="about-us">
      <Container className="about-content-holder">
        <Box className="about-title-box">
          {titles.map((title) => (
            <Title
              className="about-title"
              color={title.color}
              text={title.label}
              key={title.label}
            />
          ))}
        </Box>
        <Description className="about-description" text={t("aboutTedxCsikszereda.description")} />
      </Container>
    </StyledWrapper>
  );
};

export default AboutSection;
