import { createTheme } from "@mui/material";
import { customVariant } from "./typography.util";
import { customBreakpoints } from "./breakpoints.utils";

declare module "@mui/material/styles" {
  interface Palette {
    white: Palette["primary"];
  }
  interface PaletteOptions {
    white: PaletteOptions["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#EB0028",
    },
    white: {
      main: "#FFF",
    },
    common: {
      black: "#000000",
    },
  },
  typography: customVariant,
  breakpoints: {
    values: customBreakpoints,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          userSelect: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          fontFamily: "Inter",
          fontWeight: 900,
          fontSize: "24px",
          lineHeight: "29px",
        },
      },
    },
  },
});

export { theme };
