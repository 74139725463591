import { Grid } from "@mui/material";
import { FC } from "react";
import { TimetableSpeakerModel } from "../../../../../../types/timetable-type";
import TimetableItem from "../timetable-item/timetable-item.component.";
type Props = {
  speakersList: TimetableSpeakerModel[];
};
const TimetableListBox: FC<Props> = ({ speakersList }) => {
  return (
    <Grid container className="timetable-list-box">
      {speakersList.map(({ themeName, time, name }, index) => (
        <TimetableItem name={name} time={time} title={themeName} key={index} />
      ))}
    </Grid>
  );
};

export default TimetableListBox;
