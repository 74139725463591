import { Container } from "@mui/material";
import Title from "../../common/title/title.components";
import TalksGrid from "./components/talks-grid.component";
import StyledWrapper from "./talks.styles";

const TalksPage = () => {
  return (
    <StyledWrapper>
      <Container className="talks-content-holder">
        <Title className="talks-title" text={"TEDx Talks"} />
        <TalksGrid />
      </Container>
    </StyledWrapper>
  );
};

export default TalksPage;
