import { List } from "@mui/material";
import LanguageSelector from "../language-selector/language-selector.component";
import StyledWrapper from "./collapse-menu.style";
import MenuItem from "../menu-item/menu-item.component";
import { MenuItemModel } from "../../../../types/menu-item.type";
import { useTranslation } from "react-i18next";
import { FC } from "react";

type Props = {
  open: boolean;
  timeout: number;
  unmountOnExit: boolean;
  handleClose?: () => void;
};

const CollapseMenu: FC<Props> = ({ open, timeout, unmountOnExit, handleClose }) => {
  const { t } = useTranslation();
  const menuItems = t("header.menuPoints", { returnObjects: true }) as MenuItemModel[];
  return (
    <StyledWrapper in={open} timeout={timeout} unmountOnExit={unmountOnExit}>
      <List className="collapse-list">
        {menuItems.map((item) => (
          <MenuItem
            label={item.label}
            link={item.link}
            id={item.id}
            key={"mobile" + item.label}
            handleClose={handleClose}
          />
        ))}
        <LanguageSelector />
      </List>
    </StyledWrapper>
  );
};

export default CollapseMenu;
