import React, { FC } from "react";
import StyledWrapper from "./partners-grid.styles";
import { Grid, GridProps, Typography } from "@mui/material";
import { PartnersItem } from "../../../types/partners-item.type";

type Props = {
  title: string;
  style: GridProps;
  list: PartnersItem[];
};

const PartnersGrid: FC<Props> = ({ title, list, style }) => {
  const handleClick = (url: string) => {
    if (url) window.open(url, "_blank");
  };
  return (
    <StyledWrapper>
      <Typography className="partners-subtitle" variant="mainSemiBold">
        {title}
      </Typography>
      <Grid container spacing={style.spacing} className="partners-grid-container">
        {list.map((item, index) => (
          <Grid
            sx={{
              cursor: item.link ? "pointer" : "unset",
            }}
            key={title + index}
            className={`partners-grid-item`}
            item
            xs={style.xs}
            sm={style.sm}
            md={style.md}
            xl={style.xl}
            lg={style.lg}
            onClick={() => handleClick(item.link)}
          >
            <img src={item.image} alt="" />
          </Grid>
        ))}
      </Grid>
    </StyledWrapper>
  );
};

export default PartnersGrid;
