import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Description from "../../common/description/description.components";
import Title from "../../common/title/title.components";
import StyledWrapper from "./faq.styles";

type Topic = {
  title: string;
  description: string;
  subTitle?: string;
};

const FAQ = () => {
  const { t } = useTranslation();
  const topics = t("FaqPage.topics", { returnObjects: true }) as Topic[];

  return (
    <StyledWrapper>
      <Container className="faq-container">
        <Title className="faq-page-title" text={t("FaqPage.pageTitle")} />
        <Box className="faq-content">
          {topics.map((topic, index) => (
            <Box key={index} className="faq-topic-box">
              <Typography variant="mainExtraBold" className="faq-topic-title">
                {topic.title}
              </Typography>
              {topic.subTitle && (
                <Typography variant="mainSemiBold" className="faq-topic-subtitle">
                  {topic.subTitle}
                </Typography>
              )}
              <Description className="faq-topic-description" text={topic.description} />
            </Box>
          ))}
        </Box>
      </Container>
    </StyledWrapper>
  );
};

export default FAQ;
