import { Typography } from "@mui/material";
import logo from "../../../../../assets/images/logo.svg";
import CounterBox from "./counter/counter-box.component";
import { StyledWrapper } from "./hero-contents.styles";

const HeroContents = () => {
  return (
    <StyledWrapper>
      <img src={logo} alt="TedX" />
      <Typography className="hero-title" variant="mainBlack">
        THINK OUTSIDE
      </Typography>
      <Typography className="hero-subtitle" variant="mainBlack">
        THE FOG
      </Typography>
      <CounterBox />
      <Typography className="hero-location-text" variant="mainBlack">
        Cinema Csíki Mozi |<span> 04. 20.</span>
      </Typography>
    </StyledWrapper>
  );
};

export default HeroContents;
