import organiser1 from "../assets/images/organisers/organiser1.png";
import organiser2 from "../assets/images/organisers/organiser2.png";
import organiser3 from "../assets/images/organisers/organiser3.png";
import organiser4 from "../assets/images/organisers/organiser4.png";
import organiser5 from "../assets/images/organisers/organiser5.png";
import organiser6 from "../assets/images/organisers/organiser6.png";
import organiser7 from "../assets/images/organisers/organiser7.png";
import organiser8 from "../assets/images/organisers/organiser8.png";
import organiser9 from "../assets/images/organisers/organiser9.png";

export const highlightedMember = organiser1;

export const organizerDesktopImages = [
  organiser2,
  organiser3,
  organiser4,
  organiser5,
  organiser6,
  organiser7,
  organiser8,
  organiser9,
];

export const organizersMobileImages = [
  organiser1,
  organiser2,
  organiser3,
  organiser4,
  organiser5,
  organiser6,
  organiser7,
  organiser8,
  organiser9,
];
