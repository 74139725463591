import { useMediaQuery } from "@mui/material";
import { useCallback } from "react";
import { theme } from "../utils/theme.util";

export const useScrollToElement = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const headerHeight = isMobile ? 56 : 150;

  const handleClick = useCallback((id: string) => {
    const targetElement = document.getElementById(id);

    if (!targetElement) {
      console.error(`Element with id ${id} not found.`);

      // If not found and not on root ("/"), navigate to "/#id"
      if (window.location.pathname !== "/") {
        window.location.href = `/#${id}`;
      }

      return;
    }

    const elementPosition = targetElement.getBoundingClientRect().top;
    const offsetPosition = elementPosition - headerHeight;

    // Check if the current URL is the root ("/")
    const isRoot = window.location.pathname === "/";

    window.scrollBy({
      top: offsetPosition,
      behavior: "smooth",
    });

    // If not on the root, navigate to "/#id"
    if (!isRoot) {
      window.location.href = `/#${id}`;
    }
  }, [headerHeight]);

  return handleClick;
};
