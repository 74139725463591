export const talks = [
  {
    name: "Dr. Albert Beáta ",
    subject: "Fehérjék a ködben",
    embedLink: "https://www.youtube.com/embed/w0BCba4OZfI?si=Vh8-X9X7VlAQ8fLb",
  },
  {
    name: "Szilágyi Levente",
    subject: "Egyedül unalmas és eredménytelen",
    embedLink: "https://www.youtube.com/embed/vs0RtXR_lQk?si=GkZww5ikFhGuMabv",
  },
  {
    name: "Veres Nagy Tímea",
    subject: "Bizalom a ködön túl",
    embedLink: "https://www.youtube.com/embed/PeIt_M8GhBQ?si=Y_EKZmzyvn2VNFom",
  },
  {
    name: "Makai Edina",
    subject: "Láthatatlan élőlényekkel való meghitt kapcsolataink",
    embedLink: "https://www.youtube.com/embed/WmncIa_x8bE?si=hxNPZeoiWGuf5aYR",
  },
  {
    name: "Dr. Bodor Katalin",
    subject: "Fókuszban a levegőminőség",
    embedLink: "https://www.youtube.com/embed/zVxSf-zTdX8?si=oJyLMEK4VQRCgyTm",
  },
  {
    name: "Dr. Csala Dénes",
    subject: "Education and science communication through visualization",
    embedLink: "https://www.youtube.com/embed/eNo9ENxvLuw?si=VXDsgjayEBWaY0bT",
  },
  {
    name: "Hatházi Rebeka ",
    subject: "A háttérben látványosan",
    embedLink: "https://www.youtube.com/embed/jGN5Qv_JBQs?si=J6RrocX7ETKiSQqG",
  },
  {
    name: "Radu Predescu",
    subject: "Quit - my superpower",
    embedLink: "https://www.youtube.com/embed/fzMTUWy5RcE?si=wQ9SO47Ufge7hQDK",
  },
  {
    name: "Burján Szilárd",
    subject: "Mit tanulhatnak a székelyek a beduinoktól?",
    embedLink: "https://www.youtube.com/embed/7O3vE--hcc0?si=TakgqEilknnqumaR",
  },
  {
    name: "Dr. Melinda Sabo",
    subject: "Felejtünk, hogy emlékezhessünk - mi a célja az emberi memóriának?",
    embedLink: "https://www.youtube.com/embed/EKrwXhW4K2E?si=0ThgaYa7MTzJj1HP",
  },
  {
    name: "Gogo Bethke",
    subject: "Au-pair-ből lett milliomos",
    embedLink: "https://www.youtube.com/embed/zWcYQlLxKBA?si=4PJAWGmxv-qL_8Zo",
  },
  {
    name: "Miklós Csongor",
    subject: "Katonadolog",
    embedLink: "https://www.youtube.com/embed/XdDSZCkIkXQ?si=4bS__1GAL4tJF1b2",
  },
];
